import firebase, { db } from "../firebase";
import {ItemTypes, Locations, BookPermissions, UserPermission} from './Consts'
import { v4 as uuidv4 } from "uuid";
import {getCurrentUser} from "../Common/LocalStorage";
import {performBookSearch, performsStudentSearch} from '../Common/SearchAPI'
import {doc, collection, arrayUnion, setDoc, getDoc, updateDoc, getDocs, deleteDoc,query, where, orderBy,} from "firebase/firestore"; 
import { composeInitialProps } from "react-i18next";
import { Timestamp } from "firebase/firestore";
import { async } from "@firebase/util";
import { getImageDimensionsByFile, decryptImage } from "./FileStorageUtils";
import { useParams } from "react-router-dom";
/*Hirerchy:
User > Books > Book pages > boxes on each page
each box referes to a storage item (text/image) within a designated bucket */

  const newestTermsVersion = process.env.REACT_APP_TERMS_VER;
  const newestPrivacyVersion = process.env.REACT_APP_PRIVACY_VER;

export const get_your_stories = async (user_email, locale = '') => {
    const stories = []
    try{
        const storiesCollection = collection(db, Locations.BOOKS_TABLE);
        const q = query(storiesCollection, 
                        where('creator_email', '==', user_email),
                        // where('deleted','!=', "true"),
                        orderBy('updated_on', 'desc') // Order by 'updated_on' field in descending order
                        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                let docData = doc.data()
                const storyLocale = docData.locale || docData.student_attributes?.locale;
                if (docData && ((docData.deleted && docData.deleted != 'true') || !docData.deleted)){
                    if (locale === "IL" && storyLocale === "IL") {
                    stories.push(docData);
                    }else if (locale !== "IL" && storyLocale !== "IL") {
                    stories.push(docData);
                }
            }
        }
    })
  } catch (err) {
    console.error("failed to get user stories",err)
  }
  return stories
}

export const get_all_stories = async (user_query=null,locale = '') => {
    const stories = []
    try{
        const storiesCollection = collection(db, Locations.BOOKS_TABLE);
        const q =
            user_query ?
            query(storiesCollection, 
                where('permission', '==', "public"),
                where('book_name', '>=', user_query),
                // orderBy('updated_on', 'desc') // Order by 'updated_on' field in descending order

                )
            : query(storiesCollection, 
                where('permission', '==', "public"),
                )
        const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        let docData = doc.data()
        const storyLocale = docData.locale || docData.student_attributes?.locale;
        if (docData && ((docData.deleted && docData.deleted != 'true') || !docData.deleted)){
            if (locale === "IL" && storyLocale === "IL") {
            stories.push(docData);
          } else if (locale !== "IL" && storyLocale !== "IL") {
            stories.push(docData);
          }
        }
      }
    })

    stories.sort((a, b) => {
    const dateA = new Date(a.updated_on);
    const dateB = new Date(b.updated_on);
    return dateB - dateA; // Sort in descending order
    });

  } catch (err) {
    console.error("failed to get all stories",err)
  }
  return stories
}

export const get_org_stories = async (group_id, locale=  '') => {
    const stories = []
    try{
        const storiesCollection = collection(db, Locations.BOOKS_TABLE);
        const q =
            query(storiesCollection, 
                where('permission', '==', "org-public"),
                where('group_id', '==', group_id)
                )
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                let docData = doc.data()
                const storyLocale = docData.locale || docData.student_attributes?.locale;
            if (docData && ((docData.deleted && docData.deleted != 'true') || !docData.deleted)){
                if (locale === "IL" && storyLocale === "IL") {
                    stories.push(docData);
                }else if (locale !== "IL" && storyLocale !== "IL") {
                    stories.push(docData);
                }
            }
        }
        });
    }
    catch(err){
        console.error("failed to get org stories",err)
    }
    return stories
}

export const createUser = async (email, fullName, account_type, auth_id) => {
    let result = false
    try {
        console.log("attempting to add user="+email+" to "+Locations.USERS_TABLE+" table")
        const userConsent = {
            privacy: [{
                    version: newestPrivacyVersion,
                    signed: Date().toLocaleString()
                    }],
                    terms: [{
                    version: newestTermsVersion,
                    signed: Date().toLocaleString()
                }]
            }
    
            const docData = {
                account_type: account_type,
                auth_id: auth_id,
                full_name: fullName,
                books: [],
                students: {},
                email: email,
                user_consent:userConsent,
                updated_on: Date().toLocaleString()
            }
            const docRef = doc(db, Locations.USERS_TABLE, email);
            setDoc(docRef, docData);
            result = docData;
      } 
      catch (e) {
        console.error("Error adding new user document: ", e);
      }
      return result
};

export const createLead = async (email, fullName, organization, title, phone="") => {
    try {
        console.log("attempting to add lead="+email+" to "+Locations.LEADS_TABLE+" table")
        const docData = {
            organization: organization,
            title: title,
            phone: phone,
            email: email,
            fullName : fullName,
            updated_on: Date().toLocaleString()
        }
        const docRef = doc(db, Locations.LEADS_TABLE, email);
        setDoc(docRef, docData);
        return docData;
      } 
      catch (e) {
        console.error("Error adding new lead document: ", e);
      }
};

export const updateBookRefForUser = async (email, bookRef) =>{
    let return_val = false;
    try{
        console.log("attempting to update bookRef:"+bookRef+" for user:"+email)
        const userDocRef = doc(db, Locations.USERS_TABLE, email);
        await updateDoc(userDocRef, {
            books: arrayUnion(bookRef), 
        },{merge:true}).then(return_val = true);
    }
    catch{
        console.log("failed to update bookRef:"+bookRef+" for user:"+email)
    }
    return return_val
}

export const get_organization = async(user_organization)=>{
    let result = false
    const organizationDocRef = doc(db, Locations.ORGANIZATIONS_TABLE, user_organization);
    await readDoc(organizationDocRef).then(async (response) =>{
            result = response
    })
    return result;
}

export const get_group_name = async(group_id, org_id, lng)=>{
    let result = ""
    await get_organization(org_id).then(org=>{
        if (org && org.groups && org.groups.length > 0){
            org.groups.forEach(group => {
                if (group.id && (group.id == group_id) && group.name){
                    result = (lng=='he') ? group.name.HE : group.name.EN
                }   
            })
        }
    })
    return result;
}

const readDoc = async (docRef) =>
{
    let return_val = false;
    try
    {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return_val = docSnap.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such page! docRef:"+docRef);
            return_val = false
        }
    }
    catch(err){
        console.error("failed to read docRef:"+docRef, err)
    }
    return return_val;
}

export const get_user = async (email) =>
{
    let result = false
    const userDocRef = doc(db, Locations.USERS_TABLE, email);
    console.log("attempting to get user:"+email)
    await readDoc(userDocRef).then(async (response) =>
        {
            //if user doesn't exist in usersDB - create it
            console.log("read user doc: "+ response)
            if (!response) 
            {
                let current_user = getCurrentUser()
                await createUser(current_user.email, current_user.displayName, 0, current_user.uid).then((create_user_response) => {
                    result = create_user_response
                    console.log("new user created: "+ create_user_response)
                })
            }
            else //user already exist, yield it
            {
                result = response
            }
            
        },
        (err) => {
            //catches the errors
            console.log("failed to get user:"+email,err)
        }
    )
    console.log("get user. got result: " + result)
    return result
}

export const update_user_properties = async (user_email, props_to_update_array, values_to_update=null)=>{
    let result = false;
    try{
        await get_user(user_email).then(async (current_user_data) => {
            console.log("got user: "+current_user_data)
            let updated_user_data = current_user_data
            if (Array.isArray(props_to_update_array))
            {
                props_to_update_array.forEach((property) => {
                    switch (property){
                        case 'user_consent':
                            updated_user_data = update_user_consent(updated_user_data)
                            break
                        case 'organizations':
                            updated_user_data = update_user_org_and_group(updated_user_data, values_to_update)
                            break
                    }
                });
            } 
            //update DB
            const docRef = doc(db, Locations.USERS_TABLE, user_email);
            await setDoc(docRef, updated_user_data).then(response=>{
                console.log("updated doc ref:" + docRef + "got response:" +response)
                result = updated_user_data;
            }) 
        })
    }catch(err){
        console.error("failed to update user properties:"+user_email,err)
    }
    console.log("updated user. got result: " + result)
    return result;
}

const update_user_org_and_group = (current_user_data, values_to_update)=>{
    let updated_user_data = current_user_data;
    if (values_to_update.org_id && values_to_update.group_id){
        if(!current_user_data.organizations){
            updated_user_data.organizations = {id:values_to_update.org_id,groups:new Array({group_id:values_to_update.group_id, permission:UserPermission.ORG_USER})}
        }
    }
    return updated_user_data
} 

const update_user_consent = (current_user_data)=>{
    let updated_user_data = current_user_data;
    try{
        if(!current_user_data.user_consent){
            updated_user_data.user_consent = {
                privacy:[],
                terms:[],
            };
        }
        const newValueTerms = {
                version: newestTermsVersion,
                signed: Timestamp.now()
            };
        const newValuePrivacy = {
                version: newestPrivacyVersion,
                signed: Timestamp.now()
            };
        updated_user_data.user_consent.privacy.push(newValuePrivacy);
        updated_user_data.user_consent.terms.push(newValueTerms);
    }
    catch(err){
        console.log("faliled to update consent for:"+current_user_data)
    }
    return updated_user_data
}

export const createOrganizationStudent = async (group_id, student_to_add)=>{
    let result = false
    try{
        let student_map = {}
        let student_key = group_id+"_"+Date.now()
        student_map=student_to_add
        student_to_add.key = student_key
        student_to_add.group_id = group_id
        console.log("updated_user_data to update:"+JSON.stringify(student_map))

        //update DB
        const docRef = doc(db, Locations.STUDENTS_TABLE, student_key);
        setDoc(docRef, student_map);
        result = student_map;  
    }

    catch(err){
        console.error("faild to upload student data", err);
    }
    return result
}

export const updateOrganizationStudent = async (owner_id, student_key, student_props)=>{
    let result = false;
    try{
        let updated_user_data = {}
        updated_user_data=student_props
        //update DB
        const docRef = doc(db, Locations.STUDENTS_TABLE, student_key);
        setDoc(docRef, updated_user_data);
        result = updated_user_data;
    }
    catch(err){
        console.error("faild to upload student data", err);
    }
    return result
}

export const readOrganizationStudentMetadata = async (owner_id,student_key) => {
    let result = []
    try {
        console.log("attempting metadata of student:"+student_key)
        const docRef = doc(db, Locations.STUDENTS_TABLE, student_key);
        await readDoc(docRef).then((response) => {
            result = response
        })
    }
    catch(err){
        console.error("failed to read student:"+student_key+" metadata")
    }
    return result
}

export const getGroupStudents = async (group_id)=>{
       try {
        const studentsCollectionRef = collection(db, Locations.STUDENTS_TABLE);
        const q = query(studentsCollectionRef, 
                        where('group_id', '==', group_id)
                        //where('deleted','!=', false)
                        );
        const querySnapshot = await getDocs(q);

        const students = [];
        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                let docData = doc.data()
                if (docData && ((docData.deleted && docData.deleted != 'true') || !docData.deleted)){
                    const studentData = doc.data();
                    students.push(studentData);
                }
            }
        });

        return students;
    } catch (err) {
        // Handle errors here
        console.error(err);
        return -1;
    }
}

export const getSingleGroupStudent = async (user_email, student_id)=>{
    let result = {}
    if (student_id && student_id != '')
    {
        const studentDocRef = doc(db, Locations.STUDENTS_TABLE, student_id);
        await readDoc(studentDocRef).then((response) =>
            {
                console.log('Student Document data:'+JSON.stringify(response))
                result = response
                
            },
            (err) => {
                //catches the errors
                console.log(err)
                result = -1
            }
        )
    }
    return result
}

const getNumberOfBooksExistingUser = async (email) => {
    let result = -1
    console.log("attempting to fetch number of books for user:"+JSON.stringify(email))
    
    let filters = ['creator_email:'+email]
    console.log("filtering out:"+filters)
    await performBookSearch('', filters).then((search_response) =>{
    if (search_response && search_response.nbHits)
        console.log("got number of books:"+search_response.nbHits+" for user:"+email)
        result = search_response.nbHits
    })

    return result
}

const readSingleBook = async (book_ref) => {
    let result = null
    console.log('reading book:'+book_ref.path)
    const bookRef = doc(db, book_ref.path)
    await readDoc(bookRef).then((single_book_response) => 
    {
        console.log("read ref:"+bookRef+" got:"+single_book_response.book_id)
        //return single_book_response
        result = single_book_response
    },
    (err) => 
    {
        console.error("falied to read book details",err)
    })
    return result
}


export const getUserBooks = async (email) => {
    let result = []
    let promises = null
    const docRef = doc(db, Locations.USERS_TABLE, email);
    await readDoc(docRef).then((book_refs) => 
    {
        console.log("response.books:"+JSON.stringify(book_refs.books))
        console.log("number of books for "+email+" is "+book_refs.books.length)

        //read book content
        promises = book_refs.books.map(async ref => {
            return await readSingleBook(ref)
        })
    },
    (err) => {
        console.error("failed to retrieve book refrences for user:"+email,err)
    })

    result = await Promise.all(promises)
    return result
}

export const getStudent = async (user_email, student_id) => {
    let result = {}
    const docRef = doc(db,Locations.USERS_TABLE, user_email)
    await readDoc(docRef).then((user_data) => {
        if (user_data && user_data.students && student_id && user_data.students[student_id.toLowerCase()])
        {
            result = user_data.students[student_id.toLowerCase()]
            console.log("for student:"+student_id+" got details:"+JSON.stringify(result))
        }
    },
    (err) => {
        console.error("failed to retrieve student data for studnet:"+student_id,err)
    })
    return result
}

export const getUserStudents = async(user_email)=>{
    let result=[]
    let promises=null
    const docRef = doc(db,Locations.USERS_TABLE, user_email)
    await readDoc(docRef).then((student_refs)=>
    {
        console.log("student refs:"+student_refs)
        if(student_refs && student_refs.students)
        {
            console.log("number of students for " + user_email+ " is "+Object.keys(student_refs.students).length);
        
             //read student content
            // promises = student_refs.students.map(async ref => {
            //     return await readSingleStudent(ref)
            // })
            result = student_refs.students
        }
    },
    (err)=>{
        console.error("failed to retrieve book refrences for user:"+user_email,err)
    })
    // result=await Promise.all(promises)
    return result
}

    const deleteObjectElement = async (documentId, collection, objectPropertyName, elementToRemove) => {
    const docRef = doc(db, collection, documentId);
    try {
        // Get the current document data
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            // Extract the array from the document
            const currentObject = docSnapshot.data()[objectPropertyName] || {};

            // Check if the element to remove exists in the array
             if (currentObject.hasOwnProperty(elementToRemove)) {
                // Create a copy of the object without the specified key
                const updatedObject = { ...currentObject };
                delete updatedObject[elementToRemove];

                // Use update to update the document with the modified object
                await updateDoc(docRef, {
                    [objectPropertyName]: updatedObject
                });
                console.log('Array element removed successfully.');
            } else {
                console.log('Element not found in the array.');
            }
        } else {
            console.log('Document not found.');
        }
    } catch (error) {
        console.error('Error deleting array element:', error);
    }
}

export const deleteUserStudent = async (user_email, student_id) => {
    let result = false
    try{
        console.log("attempting to delete stduent:"+student_id+" for user:"+user_email) 
        await deleteObjectElement(user_email, Locations.USERS_TABLE, 'students', student_id).then(response => {
            if (response && response == true)
            {
                console.log("succesfully deleted student:"+student_id+" from user:"+user_email)
                result = true
            }
            else
                console.log("failed to delete student:"+student_id+" from user:"+user_email)
        })
    }
    catch(err){
        console.error("failed to delete student:"+student_id+" from user:"+user_email,err)
    }
    return result
}


export const readPage = async (book_id, page_id) =>
{
    let result = null;
    console.log("attempting to read page_id:"+page_id+" from book_id:"+book_id)
    //page_id example: idoz@ozzystory.com_7_1
    const pageRef = doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id)
    await readDoc(pageRef).then((response) => 
        {
            result = response
        },
        (err)=>{
            console.error("error reading page:"+page_id+" on book:"+book_id,err)
            result = false
        })
    return result
}

export const getNumberOfPages = async(book_id) => {
    let result = 1;
    console.log("attepmting to get number of pages for book_id:"+book_id)

    await readBookMetadata(book_id).then(async (bookMetadata) =>{
        let next_page_id = null
        if (bookMetadata && bookMetadata.first_page)
            next_page_id = bookMetadata.first_page
        else console.log("no first page found for book_id:"+book_id)
        while (next_page_id!=null)
        {
            await readPage(bookMetadata.book_id, next_page_id).then(
                (response) =>{
                    //increment page pointer to next page & increase total page counter
                    if (response && response.next_page){
                        result++
                        next_page_id = response.next_page
                    } 
                    else next_page_id = null
                },
                (error) => {
                    console.error("failed to get number of pages for book_id: "+book_id+" while next page is:"+next_page_id,error)
                }
            )
        }
    })
    return result
}

export const addPage = async (book_id, email, fullName, account_type, auth_id,
                              pages_content, page_id, next, prev, pdfURL="", pageBackground,lang="EN",background_attributes={})  =>
{
    //console.log("pages_content.length="+pages_content.length)
    if (page_id && page_id!='')
    {
        if (!get_user(email)) createUser(email, fullName, account_type, auth_id).then((response) => {
            console.log("new user "+email+" added. response:"+response && response.email ? response.email :"")
            addPageWithID(book_id
                , pages_content, page_id, next, prev, pdfURL, pageBackground,lang,background_attributes) 
        },
        (err) => {
            console.error("failed to add user "+email+" for page addition",err)
        })
        //if user exist, use it and add new page
        else 
            addPageWithID(book_id, pages_content, page_id, next, prev, pdfURL, pageBackground,lang,background_attributes) 
    }
}
export const addPageWithID = async (book_id, page_content, page_id, next, prev, pdfURL="", pageBackground="#FFFFFF",lang="EN",background_attributes={}) =>
{   
    try {
        //if user missing, add it, but don't depend book creation on it
        //if (!user_exist(email)) createUser(email, fullName, account_type, auth_id).then(console.log("new user "+email+" added"))
        if (page_id && page_id!='')
        {
            const pageRef = doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id);
            console.log("attempting to add new page, page_id="+page_id+", next_page:"+next+", prev_page:"+prev+", lang:"+lang)
            setDoc(pageRef, {page_id:page_id, content:page_content, next_page:next, prev_page:prev, 
                            pdf_url:pdfURL, background:pageBackground,lang:lang.toUpperCase(),background_attributes:background_attributes, updated_on: Date().toLocaleString()})   
        }
    }
    catch (e) {
        console.error("Error adding document. page_id: "+page_id+" ,page_content: "+page_content, e);
    }
    return book_id;
}

// updatePageNextPrev(book_id, next_page,prev_page,1)
const updatePageNextPrev = async (book_id, page_id, newField, nextOrPrev) =>
{   
    let result = false
    try {
        console.log("attempting to update page pointers for page_id="+page_id)
        if (page_id) //do nothing if page_id==null
        {
            const pageRef = doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id);
            // nextOrPrev contain 0 for update next. 1 for update prev
            nextOrPrev==0 ? 
            await updateDoc(pageRef, {next_page:newField}).then(
                (response) => {result = response},
                (error) => {console.error("failed to update next_page value:"+newField+" for page_id:"+page_id,error)})
            : updateDoc(pageRef, {prev_page:newField}).then(
                (response) => {result = response},
                (error) => {console.error("failed to update prev_page value:"+newField+" for page_id:"+page_id,error)})
        }
    }
    catch (e) {
        console.error("Error updating decessor for bookid: "+book_id+" ,page_id: "+page_id+" ,newField:"+newField+" ,nextOrPrev: "+nextOrPrev, e);
    }
    return result;
}

export const updateNextPagePointer = async (book_id, page_id, new_pointer_ref) =>
{
    //return updatePageNextPrev(book_id, page_id, new_pointer_ref, 0)
    let result = false
    try {
        console.log("attempting to update page pointers for page_id="+page_id)
        if (page_id) //do nothing if page_id==null
        {
            const pageRef = doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id);
            await updateDoc(pageRef, {next_page:new_pointer_ref}).then(
                (response) => {result = response},
                (error) => {console.error("failed to update next_page value:"+newField+" for page_id:"+page_id,error)})
        }
    }
    catch (e) {
        console.error("Error updating decessor for bookid: "+book_id+" ,page_id: "+page_id+" ,newField:"+newField+" ,nextOrPrev: "+nextOrPrev, e);
    }
    return result;
}

export const updatePrevPagePointer = async (book_id, page_id, new_pointer_ref) =>
{
    //return updatePageNextPrev(book_id, page_id, new_pointer_ref, 1)
    let result = false
    try {
        console.log("attempting to update page pointers for page_id="+page_id)
        if (page_id) //do nothing if page_id==null
        {
            const pageRef = doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id);
            await updateDoc(pageRef, {prev_page:new_pointer_ref}).then(
                (response) => {result = response},
                (error) => {console.error("failed to update next_page value:"+newField+" for page_id:"+page_id,error)})
        }
    }
    catch (e) {
        console.error("Error updating decessor for bookid: "+book_id+" ,page_id: "+page_id+" ,newField:"+newField+" ,nextOrPrev: "+nextOrPrev, e);
    }
    return result;
}

export const updateLastCreatedPage = async (book_id, new_last_created_page_id) =>
{   
    try 
    {
        console.log("attempting to update last_created_page_id, book_id="+book_id)
        const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
        const res =  await updateDoc(bookRef, {last_created_page_id:new_last_created_page_id});
    }
    catch (e) {
        console.error("Error updating document: ", e);
    }
    return book_id;
}

export const updateBookDownloads = async (book_id) =>
{   
    try 
    {
        console.log("attempting to update dowloads, book_id="+book_id)
        const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
        await readDoc(bookRef).then(async(response) => {
            let downloads=0;
            if(response.downloads){
                downloads=response.downloads;
            }
            let increase_val=downloads+1;
            await updateDoc(bookRef, {downloads:increase_val}).then( 
                () => {
                    console.log("succesfully increase downloads:"+increase_val+" on book:"+book_id)
                },
                (error) => {
                    console.log("failed to increase downloads on book:"+book_id, error)
                })
    })
    }
    catch (e) {
        console.error("Error increase downloads in book_id: "+book_id, e);
    }
    return book_id;
}

export const convertStudentIdToName = async (user_email, student_id) => {
    let result = ""
    try {
        await getStudent(user_email,student_id).then((res)=>{
          if (typeof res === 'object'){
                result = res.name
            }
          })
        } 
      catch (err) {
        console.error("failed to fetch student.", err);
      }
      return result
  }

export const copyBook = async (book_id, newBookId, creatorEmail, newClonekidName, imagesURL, newStudentId=null, 
                                studentAttributes, privateContentAttributes, current_user_name, defaultPrivateContent, groupId=null, groupPermission=null) =>
{   
    let return_val=false;
    try 
    {
        
        console.log("attempting to copy book, book_id="+book_id)
        const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
        await readDoc(bookRef).then(
            async(response) => {
                let old_book_email=response.creator_email;
                let old_kid_name = response.kid_name ? response.kid_name : 
                                        (response.student_attributes ? 
                                            response.student_attributes.name :
                                            await convertStudentIdToName(old_book_email,response.student_id))

                let newBook=response;
                newBook.book_id=newBookId;
                newBook.creator_email=creatorEmail;
                newBook.creator_name = current_user_name;
                newBook.downloads=0;
                newBook.student_id=newStudentId;
                newBook.student_attributes={...studentAttributes};
                newBook.private_cloned_content_attributes={...privateContentAttributes}
                newBook.kid_name = newClonekidName
                if (groupId) newBook.group_id = groupId
                if(response.is_free){
                    delete newBook.is_free;
                    newBook.story_copy_disallowed = true;
                }
                if(response.first_page){
                    newBook.first_page=response.first_page.replace(book_id, newBookId);
                }

                if(response.last_created_page_id){
                    newBook.last_created_page_id=response.last_created_page_id.replace(book_id, newBookId);
                }

                response.external_symbols_counter ?
                    newBook.external_symbols_counter=response.external_symbols_counter :
                    newBook.external_symbols_counter = 0

                response.page_orientation ?
                newBook.page_orientation=response.page_orientation :
                newBook.page_orientation = 'portrait'    
                
                newBook.permission= groupPermission ? groupPermission : BookPermissions.PRIVATE;
                newBook.private_content={};
                let source_private_content=response.private_content
                for (let i=0;i< imagesURL.length; i++) {
                    newBook.private_content[Object.keys(imagesURL[i])[0]]=imagesURL[i][Object.keys(imagesURL[i])[0]];
                    
                }
                if(defaultPrivateContent){
                    newBook.private_content_attributes={...defaultPrivateContent}
                }
                newBook.cloned_from=book_id;
                newBook.updated_on= Date().toLocaleString();
                
                const docRef = doc(db, Locations.BOOKS_TABLE, newBookId)
                await setDoc(docRef, newBook).then(
                    async(response) => {
                        await copyPages(book_id,newBookId, old_book_email, creatorEmail, old_kid_name, newClonekidName,imagesURL,newBook,defaultPrivateContent,source_private_content).then(
                            async(res)=>{
                                console.log("copyPages from book_id:"+book_id+" to new book:"+newBookId+" return with respone:"+res)
                                await updateBookRefForUser(creatorEmail,docRef).then((response)=>
                                {
                                    return_val=true;
                                    console.log("updated book ref:"+docRef+" for user:"+creatorEmail)
                                },
                                (err) =>{
                                    console.error("failed to update book ref:"+docRef+" for user:"+creatorEmail,err)
                                })
                        })
                        
                        
                        },
                    (error) => {
                        console.log("failed to save copied book:"+book_id, error)
                    })
            },
            (error) => {
                console.log("failed to copy book:"+book_id, error)
            })
    
    }
    catch (e) {
        console.error("Error copyBook: "+book_id+" newBookId:"+newBookId, e);
    }
    return return_val;
}
export let numOfCopiedPages=0;
export const copyPages = async (old_book_id, new_book_id, old_creator, new_creator, old_kid_name,new_kid_name,imagesURL,newBook,defaultPrivateContent,source_private_content) => {
    let return_val=false;
    try{
       
        numOfCopiedPages=0;
        await getDocs(collection(db, Locations.BOOKS_TABLE, old_book_id, "pages")).then(
            async(snap) => {
                let pagesNum=snap.size;
                var results =await Promise.all( snap.docs.map(async (doc)  => {
                    await copyPage(doc.data(),old_book_id,new_book_id, old_kid_name,new_kid_name,imagesURL,newBook,defaultPrivateContent,source_private_content).then( (response) => {
                        if(response){
                            numOfCopiedPages++;
                        }
                        if(numOfCopiedPages==pagesNum)
                        {
                            return_val=true;
                            
                        }
                });
                  }));
               

                },
                (err) => {
                    console.error("error retreiving pages in book_id:"+old_book_id, err)
                });
                     
        }
        catch (e) {
            console.error("Error copyPages: ", e);
        }
        return return_val;
    }

    const extractFirstName = (name) => {
        let index_of_space_in_name = name.indexOf(' ')
        return index_of_space_in_name > 0 ? name.substring(0, index_of_space_in_name) : name
    }

    export const copyPage = async (pageData, old_book_id, new_book_id, old_kid_name, new_kid_name, imagesURL, newBook,defaultPrivateContent,source_private_content) => {
        console.log("copying page:"+pageData.page_id+",new_book_id:"+new_book_id+",old_kid_name:"+old_kid_name+",new_kid_name:"+new_kid_name)
        let return_val=false;
        try 
        {
            let new_kid_first_name = new_kid_name ? extractFirstName(new_kid_name) : ""
            let old_kid_first_name = old_kid_name ? extractFirstName(old_kid_name) : ""

            if(pageData.next_page){
                pageData.next_page= pageData.next_page.replace(old_book_id,new_book_id);
            }
            if(pageData.prev_page){
                pageData.prev_page=pageData.prev_page.replace(old_book_id,new_book_id);
            }
            if(pageData.page_id){
                pageData.page_id=pageData.page_id.replace(old_book_id,new_book_id);
            }
            if(pageData.content){
                for(let piece of pageData.content){
                    if(piece.type==ItemTypes.TEXT_BOX){
                        if(piece.content){
                            const tempElement = document.createElement("div");
                            tempElement.innerHTML = piece.content;

                            const childNodes = tempElement.childNodes;

                            let lowerCaseContent = '';

                            for (let i = 0; i < childNodes.length; i++) {
                            if (childNodes[i].nodeType === 3) {
                                // nodeType 3 is a text node
                                lowerCaseContent += childNodes[i].nodeValue.toLowerCase();
                            } else {
                                lowerCaseContent += childNodes[i].outerHTML;
                                 }
                            }
                            if (old_kid_name && new_kid_first_name)
                                piece.content = lowerCaseContent.replace(old_kid_name.toLowerCase(), new_kid_first_name).replace(old_kid_first_name, new_kid_first_name);
                        }
                            // piece.content=piece.content.toLowerCase().replace(old_kid_name.toLowerCase(),new_kid_first_name).replace(old_kid_first_name,new_kid_first_name);
                        }
                    
                    if(piece.type==ItemTypes.IMAGE){
                    try{
                     const privateContentValues = Object.keys(newBook.private_cloned_content_attributes);
                    if(piece.isPrivate == true && privateContentValues.some((desc) => desc === piece.content)){
                        piece.width = newBook.private_cloned_content_attributes[piece.content]['nat-width'] && newBook.private_cloned_content_attributes[piece.content]['nat-height'] && piece.height ?
                        Math.round(newBook.private_cloned_content_attributes[piece.content]['nat-width']/newBook.private_cloned_content_attributes[piece.content]['nat-height']*piece.height*100)/100 :
                        piece.width
                    }
                     if (piece.isPrivate == true){
                        if(Array.isArray(defaultPrivateContent) && 
                        !defaultPrivateContent.some(obj => piece.content in obj)) {
                                const index = pageData.content.indexOf(piece);
                                pageData.content.splice(index, 1);
                        }
                        else if(Array.isArray(source_private_content) && 
                                !source_private_content.some(obj => piece.content in obj)){
                                    const index = pageData.content.indexOf(piece);
                                    pageData.content.splice(index, 1);
                            }
                        }
                    }catch(err){
                        console.error("Error in copy private image",err)
                    }
                    }
                }
            }

            const pageRef = doc(collection(db, Locations.BOOKS_TABLE, new_book_id, "pages"),pageData.page_id);
            console.log("attempting to add new page, page_id="+pageData.page_id)
            await setDoc(pageRef, pageData).then(
                 (res) => {
                    console.log("copy new page, page_id="+pageData.page_id)
                     return_val=true;
                    },
                    (err) => {
                        console.error("error add new page, page_id="+pageData.page_id, err)
                    });
        }
        catch (e) {
            console.error("Error copyPage: ", e);
        }
        return return_val;
    }


export const deletePage = async (book_id, page_id) => {
    let result = false
    try{


        console.log("attempting to delete page:"+page_id+" from book:"+book_id) 
        await deleteDoc(doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id)).then(
            () => {
                console.log("succesfully delete page:"+page_id+" from book:"+book_id)
                result = true
            },
            (error) => {
                console.log("failed to delete page:"+page_id+" from book:"+book_id, error)
            }
        )
    }
    catch(err){
        console.error("failed to delete page:"+page_id+" from book:"+book_id,err)
    }
    return result;
}


export const readBookMetadata = async (book_id) => {
    let result = []
    try {
        console.log("attempting metadata of book_id:"+book_id)
        const docRef = doc(db, Locations.BOOKS_TABLE, book_id);
        await readDoc(docRef).then((response) => {
            result = response
        })
    }
    catch(err){
        console.error("failed to read book:"+book_id+" metadata")
    }
    return result
}

export const updateFirstPageRef = async (book_id, first_page_to_update) => {
    let result = false
    const docRef = doc(db, Locations.BOOKS_TABLE, book_id)
    updateDoc(docRef, {first_page:first_page_to_update}).then(response => {
        console.log("updated first_page metadata for book_id:"+book_id+", got:"+response)
        result = response
    })
    return result
}

export const updateSingleMetadataProperty = async (property_id, property_to_update, property_value, table=Locations.BOOKS_TABLE) => {
    let result = false
    const docRef = doc(db, table, property_id)
    const objectToMerge = '{"'+property_to_update+'":"'+property_value+'"}'
    await updateDoc(docRef, JSON.parse(objectToMerge)).then(()=>{
        console.log("updated "+property_to_update+" metadata for property_id:"+property_id+" to "+property_value)
        result = true
    })
    return result
}

export const updateSinglePageMetadataProperty = async (book_id, page_id, property_to_update, property_value) => {
    let result = false
    const pageRef = doc(collection(db, Locations.BOOKS_TABLE, book_id, "pages"),page_id);
    const objectToMerge = { [property_to_update]: property_value };
    updateDoc(pageRef, objectToMerge).then(()=>{
        console.log("updated "+property_to_update+" metadata for book_id:"+book_id+", page_id:"+page_id+" to "+property_value)
        result = true
    })
    return result
}

export const updateBookMetadata = async (creator_email, book_id, book_name, kid_image,kid_age, kid_concentration_level,
                                        kid_language_comprehension, kid_main_challenges,
                                        locale,category,themeImageUrl,first_page,last_created_page_id,
                                        permission='private',student_id="",student_attributes={},
                                        deleted='false',creator_name='',external_symbols_counter,
                                        page_orientation, groupid=null) => {
    let result = false
    try
    {
        const docData = {
            book_id: book_id,
            book_name: book_name,
            kid_image: kid_image,
            kid_age:kid_age || 4,
            kid_concentration_level: kid_concentration_level,
            kid_language_comprehension: kid_language_comprehension,
            kid_main_challenges :kid_main_challenges,
            locale:locale,
            category: category,
            creator_email: creator_email,
            updated_on: Date().toLocaleString(),
            first_page:first_page,
            last_created_page_id:last_created_page_id,
            theme_img_url: themeImageUrl,
            permission:permission,
            student_id:student_id,
            student_attributes:student_attributes,
            deleted:deleted,
            external_symbols_counter:external_symbols_counter,
            page_orientation:page_orientation, 
            creator_name:creator_name
        }
        if (groupid) docData.group_id = groupid
        console.log("updating book meta. book_id:"+book_id+", book_name:"+book_name+", kid_image:"+kid_image+
                   ", category:"+category+" ,creator_email:"+creator_email+" ,theme_img_url:"+themeImageUrl+" ,first_page:"+first_page+", last_created_page_id:"
                    +last_created_page_id+", permission:"+permission+", student_id:"+student_id+
                    ",student_attributes:"+JSON.stringify(student_attributes ? student_attributes : {})+
                    ", deleted:"+deleted+", creator_name:"+creator_name+",external_symbols_counter:"+external_symbols_counter+",page_orientation:"+page_orientation+", group_id:"+groupid)
                    
        console.log("docData:"+docData ? JSON.stringify(docData): "no-data")
        const docRef = doc(db, Locations.BOOKS_TABLE, book_id)
        setDoc(docRef, docData, {merge:true})
        await updateBookRefForUser(creator_email,docRef).then((response)=>
        {
            console.log("updated book ref:"+docRef+" for user:"+creator_email)
            result = docData
        },
        (err) =>{
            console.error("failed to update book ref:"+docRef+" for user:"+creator_email,err)
        })
    }
    catch (err)
    {
        console.error("failed to update book meta data for book_id:"+book_id, err)
    }
    return result;
    };

export const createStudent = async (user_email, student_to_add)=>{
    let result = false
    try{
        console.log("attempting to add a new student for:"+user_email+" student_to_add:"+JSON.stringify(student_to_add))
        //await get_user(email).then(async(user_exist_response) =>
        await get_user(user_email).then(async (current_user_data) => {
            if (current_user_data)
            {
                let updated_user_data = current_user_data
                //updated_user_data.students[user_email+"_"+student_to_add.name] = student_to_add
                let students_map = {}
                if (updated_user_data.students) students_map = updated_user_data.students
                let added_student_id = user_email+"_"+uuidv4()
                student_to_add.key = added_student_id
                students_map[added_student_id] = student_to_add
                updated_user_data.students = students_map
                updated_user_data.updated_on = Date().toLocaleString()
                console.log("updated_user_data to update:"+JSON.stringify(updated_user_data))
    
                //update DB
                const docRef = doc(db, Locations.USERS_TABLE, user_email);
                await setDoc(docRef, updated_user_data).then()
                result = updated_user_data;
            }
        },
        (err) => {
            console.error("failed to update student object for user:"+user_email,err)
        })
    }
    catch(err){
        console.error("faild to upload student data", err);
    }
    return result
}

export const get_students = async (user_email)=>{
    let result = false
    try{
        console.log("attempting to get students for:"+user_email)
        await get_user(user_email).then(async (current_user_data) => {
            
            result = current_user_data.students;
        },
        (err) => {
            console.error("failed to get students for user:"+user_email,err)
        })
    }
    catch(err){
        console.error("faild to get students", err);
    }
    return result
}

export const store_books_for_students = async (user_email, AutoMatch_result)=>{
    let result = false
    try{
        console.log("attempting to store a books_for_students for:"+user_email+" books_for_students:"+JSON.stringify(AutoMatch_result))  
        await get_user(user_email).then(async (current_user_data) => {
            let updated_user_data = current_user_data
            updated_user_data.books_for_students = AutoMatch_result

            console.log("books_for_students to update:"+JSON.stringify(updated_user_data))

            //update DB
            const docRef = doc(db, Locations.USERS_TABLE, user_email);
            setDoc(docRef, updated_user_data);
            result = updated_user_data;
        },
        (err) => {
            console.error("failed to update books_for_students object for user:"+user_email,err)
        })
    }
    catch(err){
        console.error("faild to update books_for_students data", err);
    }
    return result
}
export const get_books_for_students = async (user_email)=>{
    let result = false
    try{
        console.log("attempting to get books_for_students for:"+user_email)
        await get_user(user_email).then(async (current_user_data) => {
            
            result = current_user_data.books_for_students;
        },
        (err) => {
            console.error("failed to get books_for_students for user:"+user_email,err)
        })
    }
    catch(err){
        console.error("faild to get books_for_students", err);
    }
    return result
}

export const updateStudent = async (user_email,student_id,student_props)=>{
    let result = false;
    try{
        console.log("attempting to update student: "+student_id)
        await get_user(user_email).then(async(current_user_data)=>{
            let updated_user_data = current_user_data
            let students_map = {}
            students_map = updated_user_data.students
            students_map[student_id]=student_props
            updated_user_data.students = students_map
            updated_user_data.updated_on = Date().toLocaleString()
            console.log("updated_user_data to update:"+JSON.stringify(updated_user_data))

                        //update DB
            const docRef = doc(db, Locations.USERS_TABLE, user_email);
            setDoc(docRef, updated_user_data);
            result = updated_user_data;
        },
            (err) => {
            console.error("failed to update student object for user:"+user_email,err)
        })
    }
    catch(err){
        console.error("faild to upload student data", err);
    }
    return result
}

export const replaceImgDescOnBook = async (book_id, oldDesc, newDesc) =>
{   
    try 
    {
        console.log("attempting to replace ImgDesc on private info, book_id="+book_id+", oldDesc:"+oldDesc)
        const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
        await readDoc(bookRef).then(async(response) => {
            var toUpdate={};
          if(response.private_content){
            var toUpdate=response.private_content;
            toUpdate[newDesc]= toUpdate[oldDesc]
          }
            const res = await updateDoc(bookRef,{"private_content":toUpdate}).then(
                (response) => {console.log("replace ImgDesc on private_content done")},
                (error) => {console.error("failed to replace ImgDesc",error)})
    
          
         
        })
        
    }
    catch (e) {
        console.error("Error replacing ImgDesc on private_content. book_id:"+book_id+", oldDesc:"+oldDesc, e);
    }
    return book_id;
}


    export const updatePrivateInfoOnBook = async (
        imgName,
        desc,
        book_id,
        defaultImageURL,
        natWidth,
        natHeight 
      ) => {
        try {
          const bookRef = doc(collection(db, Locations.BOOKS_TABLE), book_id);
          await readDoc(bookRef).then(async (response) => {
            var toUpdate = {};
            var updateAttributes = {};
            if (response.private_content) {
              toUpdate = response.private_content;
            }
      
            if (imgName !== "") {
              toUpdate[desc] = imgName;
            }
            const res = await updateDoc(bookRef, {
              "private_content": toUpdate,
            }).then(
              (response) => {
                console.log("private_content update");
              },
              (error) => {
                console.error("failed to update private_content", error);
              }
            );

            if (response.private_content_attributes) 
                updateAttributes = response.private_content_attributes;

            if (!updateAttributes[desc]) {
                updateAttributes[desc] = {};
            }
            
            if (defaultImageURL) 
                updateAttributes[desc] = { default: defaultImageURL };

            if (natHeight && natWidth){
                updateAttributes[desc]['nat-width'] = natWidth
                updateAttributes[desc]['nat-height'] = natHeight
            }

            const result = await updateDoc(bookRef, {
                "private_content_attributes": updateAttributes,
            }).then((response) => {
                console.log("private_content_attributes update" + response);
                },
                (error) => {
                    console.error("failed to update private_content_attributes",error);
                }
            );
            //}
          });
        } catch (e) {
          console.error(
            "Error updating privateInfoOnBook. book_id:" +book_id, e)
        }
        return book_id;
      };
      
    
    export const RemovePrivateInfoOnBook = async (desc, book_id) =>
    {   
        try 
        {
            console.log("attempting to remove from private info, book_id="+book_id+", imgDesc:"+desc)
            const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
            await readDoc(bookRef).then(async(response) => {
                var toUpdate={};
              if(response.private_content){
                var toUpdate=response.private_content;
              }
              delete toUpdate[desc];
                const res = await updateDoc(bookRef,{"private_content":toUpdate}).then(
                    (response) => {console.log("private_content update")},
                    (error) => {console.error("failed to update private_content",error)})
               
              
             
            })
            
        }
        catch (e) {
            console.error("Error updating privateInfoOnBook. book_id:"+book_id+", imgDesc:"+desc, e);
        }
        return book_id;
    }
    export const getPrivateImg = async (book_id, imgDesc, thissite, is_creator=true) =>
    {   
        let return_val = false;
        try 
        {
            console.log("attempting to get private info, book_id="+book_id+" imgDesc:"+imgDesc)
            const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
            await readDoc(bookRef).then((response) => {
              if(is_creator){
                if(response.private_content && response.private_content.hasOwnProperty(imgDesc)){
                    return_val = response.private_content[imgDesc];
                    if(!(return_val.startsWith("https")))
                    {                   
                        return_val= decryptImage(return_val)
                    }
                }    

                else if(response.private_content_attributes){
                        if(response.private_content_attributes.hasOwnProperty(imgDesc)){
                            return_val=response.private_content_attributes[imgDesc]['default']
                        }else{
                            // return_val=thissite+"/images/private_image.png";
                        }
                    }
              }
              else if(response.private_content_attributes){
                    if(response.private_content_attributes.hasOwnProperty(imgDesc)){
                        return_val=response.private_content_attributes[imgDesc]['default']
                    }else{
                        // return_val=thissite+"/images/private_image.png";
                }
              }
            })
        }
        catch (e) {
            console.error("Error get private img: ", e);
        }
        return return_val;
       
    }

    export const removePrivateImg = async (book_id,imgDesc) =>
    {   
        let return_val = false;
        try 
        {
            console.log("attempting to remove private info, book_id="+book_id+" imgDesc:"+imgDesc)
            const bookRef = doc(collection(db, Locations.BOOKS_TABLE),book_id);
            await readDoc(bookRef).then(async(response) => {
                var toUpdate={};
                var privateContentAttributes={}
              if(response.private_content){
                var toUpdate=response.private_content;
              }
              if(response.private_content_attributes){
                privateContentAttributes=response.private_content_attributes
              }
              if(toUpdate.hasOwnProperty(imgDesc)){
                delete toUpdate[imgDesc];
                const res = await updateDoc(bookRef,{"private_content":toUpdate}).then(
                    (response) => {console.log("private_content update");return_val=true;},
                    (error) => {console.error("failed to update private_content",error)})
            }
              if(privateContentAttributes.hasOwnProperty(imgDesc)){
                delete privateContentAttributes[imgDesc];
                const result = await updateDoc(bookRef,{"private_content_attributes":privateContentAttributes}).then(
                    (response) => {console.log("private_content_attributes update");return_val=true;},
                    (error) => {console.error("failed to update private_content_attributes",error)})
            }
               
            })
        }
        catch (e) {
            console.error("Error remove private img: ", e);
        }
        return return_val;
       
    }

    export const getOrderedPagesPDF = async (bookMetadata) => {
        //read book pages
        //for all pages - start with first page then move to next pointer
            //place pdf link on result array
        //return result array
        
        let result = []
        let next_page_id = bookMetadata.first_page
        while (next_page_id!=null)
        {
            await readPage(bookMetadata.book_id, next_page_id).then(
                (response) =>{
                    console.log("extracting book page to get pdf_url. got:"+JSON.stringify(response))
                    //place current pdf url in array
                    if (response && response.pdf_url) result.push(response.pdf_url)
                    //increment page pointer to next page
                    if (response && response.next_page) next_page_id = response.next_page
                    else next_page_id = null
                },
                (error) => {
                    console.error("failed to get pdf url for page:"+next_page_id,error)
                }
            )
        }
        return result
    }


   export const updateStudentCharacteristics = async (user_email,student_key='',updatedProps,isOrgStudent) => {
    try {
        // Call backend to generate visual characteristics
        const generatevisualURL = process.env.REACT_APP_GET_IMAGE_DESCRIPTION;
        const visualCharacteristicsResponse = await fetch(generatevisualURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_key: student_key ? student_key : updatedProps.key,
                user_email: user_email,
                image_url: updatedProps.image,
                resident_country: updatedProps.locale,
                age: updatedProps.age,
                is_org_student:isOrgStudent
            }),
        });

        // Check if response is okay
        if (visualCharacteristicsResponse.ok) {
            const data = visualCharacteristicsResponse.json();
            console.log("Visual characteristics added:", data);
        } else {
            // Log an error for non-2xx HTTP status
            console.error("Failed to add visual characteristics. Response:", visualCharacteristicsResponse.statusText);
        }
    } catch (error) {
        // Catch any unexpected errors during the fetch or JSON parsing
        console.error("An error occurred while updating student characteristics:", error);
    }
};
