import React, { useRef, useState,useEffect } from "react";
import { ImagePickerDialog } from "./Images/ImagePickerDialog";
import { SoundPickerDialog } from "./Sounds/SoundPickerDialog"
import { ImImage } from "react-icons/im";
import { BsColumnsGap, BsTextareaT } from "react-icons/bs";
import { MdOutlineChildCare } from "react-icons/md";
import { ImPaste } from "react-icons/im";
import { CgPathBack } from "react-icons/cg";
import { FaMicrophoneAlt } from "react-icons/fa";
import { SymbolSearchDialog } from "./SymbolSearchDialog";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./sidebar.css";
import { useTranslation } from "react-i18next";
import UploadBackgroundDialog from "./UploadBackgroundDialog";
import PageSetupDialog from "./PageSetupDialog";
import { concatTransformationMatrix } from "pdf-lib";
import {isPaidUser} from '../UserMgmt/UserAdmin';
import {UpgradeModalDialog } from "../BookSearch/UpgradeModalDialog";

// import {t} from 'i18next';

export const BookSideBar = ({
  uploadedImgURLs,
  onImageUploadActions,
  onSoundCreationActions,
  txtBoxCreationActions,
  onSymbolSelectionActions,
  updateImgPrivateInfo,
  viewOnlyMode,
  onBackgroundImageUploadActions,
  postBackgroundImageUploadCallback,
  pageBackground,
  setPageBackground,
  getDirection,
  book_id,
  current_page_id,
  showImagePickerFollowingPencilClick,
  setShowImagePickerFollowingPencilClick,
  uploadPastedData,
  user_data,
  setShowUpgradeModal,
  hideUpgradeModal,
  setExternal_symbols_counter,
  pageOrientation,
  setPageOrientation,
  boxes,
  authorName
}) => {
  //handle image picker dialog states
  const {t,i18n} = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [modalSymbolsShow, setSymbolsShow] = useState(false);
  const [modalBackgroundShow, setModalBackgroundShow] = useState(false);
  const [modalPageSetupShow, setModalPageSetupShow] = useState(false);
  const [modalSoundPickerShow, setModalSoundPickerShow] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgName, setImgName] = useState(null);
  const [backgroundImgName, setBackgroundImgName] = useState(null);
  const [paidUser, setPaidUser] = useState(false);
  const [upgradeModalShow, setUpgradeModalShow] = useState(false);

  //store selected image's uploaded url
  const callback = (
    imgUrl,
    imgName,
    is_Private,
    imageDescription,
    natWidth, // Add natWidth parameter
    natHeight, // Add natHeight parameter
    defaultImageURL,
    showImagePicker,
    authorName = null
  ) => {
    setImgUrl(imgUrl);
    console.log("Callback invoked. Author:", authorName);
    if (!showImagePicker) {
      if (!authorName){
      uploadedImgURLs(
        imgUrl,
        imgName,
        is_Private,
        imageDescription,
        natWidth,
        natHeight,);
    }
      else{
        uploadedImgURLs(
          imgUrl,
          imgName,
          is_Private,
          imageDescription,
          natWidth,
          natHeight,
          authorName)
      }
    }
    if (is_Private) {
      const descriptionToUpdate = showImagePicker
        ? showImagePicker
        : imageDescription;
      updateImgPrivateInfo(
        imgUrl,
        descriptionToUpdate,
        defaultImageURL,
        natWidth, // Pass natWidth to the function
        natHeight // Pass natHeight to the function
      );
    }
  };

  useEffect(() => {
    const checkUserStatus = async () => {
      const paidUser = await isPaidUser(user_data);
      setPaidUser(paidUser);
    };

    checkUserStatus();
  }, []);
  
  const showUpgradeModal = () => setUpgradeModalShow(true);
  
  const backgroundImgCallback = (imgUrl, imgName, naturalWidth, naturalHeight,backGroundAttributes) => {
    postBackgroundImageUploadCallback(imgUrl, imgName, naturalWidth, naturalHeight,backGroundAttributes)
  }

  function onImgIconClick() {
    if(!viewOnlyMode){
      setModalShow(true);
    }
  }

  function onSymoblIconClick() {
    if(!viewOnlyMode){
      setSymbolsShow(true);
    }
  }

  function onBackgroundImageClick() {
    if(!viewOnlyMode){
      setModalBackgroundShow(true);
    }
  }

  function onPageSetupClick(){
    if(!viewOnlyMode){
      setModalPageSetupShow(true);
    }
  }

  function onSoundIconClick() {  // Add function for sound icon click
    if (!viewOnlyMode) {
      setModalSoundPickerShow(true);
    }
  }

  const getImgNameCallback = (imgName) => {
    setModalShow(false);
    setShowImagePickerFollowingPencilClick('')
    console.log("hiding dialog, uploading some pic. imgName=" + imgName);
    if (imgName) {
      setImgName(imgName);
      onImageUploadActions(imgName);
    }
  }

  const soundCallback = (soundUrl, soundName, is_Private, soundDescription, duration) => {
    try {
      onSoundCreationActions(soundUrl, soundName, is_Private, soundDescription, duration);
    } catch (error) {
      console.error("Error in soundCallback: ", error);
    }
  };


  const getSymbolNameCallback = (symbolPath) => {
    setSymbolsShow(false);
    console.log("hiding symbol dialog. selectedSymbol=" + symbolPath);
    if (symbolPath) {
      console.log("selected symbol path:" + symbolPath);
      let img = new Image();
        img.src = symbolPath;
        //console.log("nat-width:"+img.naturalWidth)
        img.onload = function (){
          console.log("nat-width:"+this.naturalWidth+" nat-height:"+this.naturalHeight)
          onSymbolSelectionActions(symbolPath, '',false,'', this.naturalWidth, this.naturalHeight)
        }
    }
  }

  const getBackgroundImgNameCallback = (imgName) => {
    setModalBackgroundShow(false);
    console.log("hiding background image dialog, uploading some pic. imgName=" + imgName);
    if (imgName) {
      setBackgroundImgName(imgName);
      onBackgroundImageUploadActions(imgName);
    }
  }

  function onTxtIconClick() {
    if(!viewOnlyMode){
      console.log("text icon clicked");
      txtBoxCreationActions();
    }
  }

  const handlePasteBtnClick = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();

      for (const item of clipboardItems) {
        for (const type of item.types) {
          if (type === 'image/png' || type === 'image/jpeg') {
            // Handle the pasted image here, e.g., set it as the source of an <img> element
            await item.getType(type).then(async blob => {
              await uploadPastedData(blob).then(res => {
                console.log("done uploading pasted data via paste button")
              })
            })
            
            /*const imageURL = URL.createObjectURL(blob);

            // Set the pasted image as the source of an <img> element
            const imgElement = document.createElement('img');
            imgElement.src = imageURL;

            // Add the image to the DOM or use it as needed
            document.body.appendChild(imgElement);*/

            // You can also save the image URL to state or perform other actions with it.
          }
        }
      }
    } catch (err) {
      console.error(err.name, err.message);
    }
  };

  const PageSetupIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
    </svg>
  );

 // };

  return (
    <div
      style={{
        marginTop: "-60%",
        fontSize: "15px",
        cursor: "pointer",
        width: "80%",
      }}
    >
      <div className="sideBarContainer" onClick={onImgIconClick}>
        <div className={
          viewOnlyMode
            ? "icon_caption disabled"
            : "icon_caption"
        }>
          <ImImage />
          <p className="text_caption">{t("edit_sidebar_img_caption")}</p>
        </div>
      </div>
      <div className="sideBarContainer" onClick={onTxtIconClick}>
        <div className={
          viewOnlyMode
            ? "icon_caption disabled"
            : "icon_caption"
        }>
          <BsTextareaT alt={t("edit_sidebar_txt_caption")} />
           <p className="text_caption">{t("edit_sidebar_txt_caption")}</p>
        </div>
      </div>
      <div className="sideBarContainer" onClick={handlePasteBtnClick}>
        <div className={
          viewOnlyMode
            ? "icon_caption disabled"
            : "icon_caption"
        }>
          <ImPaste alt={t("edit_sidebar_paste")} />
           <p className="text_caption">{t("edit_sidebar_paste")}</p>
        </div>
      </div>
      <div className="sideBarContainer" onClick={onSymoblIconClick}>
        <div className={
          viewOnlyMode
            ? "icon_caption disabled"
            : "icon_caption"
        }>
          <MdOutlineChildCare alt={t("edit_sidebar_symbol_caption")} />
           <p className="text_caption">{t("edit_sidebar_symbol_caption")}</p>
        </div>
      </div>
      <div className="sideBarContainer" onClick={onBackgroundImageClick}>
        <div className={
          viewOnlyMode
            ? "icon_caption disabled"
            : "icon_caption"
        }>
          <CgPathBack alt={t("edit_sidebar_background_caption")} className="background_icon" />
           <p className="text_caption">{t("edit_sidebar_background_caption")}</p>
        </div>
      </div>
      <div className="sideBarContainer" onClick={onPageSetupClick}>
      <div className={
        viewOnlyMode
          ? "icon_caption disabled"
          : "icon_caption"
      }>
        <PageSetupIcon />
        <p className="text_caption">{t("edit_sidebar_page_setup_caption")}</p>
      </div>
    </div>
    {paidUser ? (
      <div className="sideBarContainer" onClick={onSoundIconClick}>
        <div className={viewOnlyMode ? "icon_caption disabled" : "icon_caption"}>
          <FaMicrophoneAlt />
          <p className="text_caption">{t("edit_sidebar_sound_caption")}</p>
        </div>
      </div>
    ) : (
      <div
        className="sideBarContainer"
        onClick={() => setShowUpgradeModal(true)} // or any other handler for non-paid users
      >
        <div className={viewOnlyMode ? "icon_caption disabled" : "icon_caption"}>
          <FaMicrophoneAlt />
          <p className="text_caption">{t("edit_sidebar_sound_caption")}</p>
        </div>
      </div>
    )}



      <ImagePickerDialog
        show={modalShow || showImagePickerFollowingPencilClick}
        onHide={getImgNameCallback}
        parentOfImgPickerCallback={callback}
        enablePrivateImage = {true}
        getDirection={getDirection}
        preLoadedPrivateImageDescription={showImagePickerFollowingPencilClick}
        user_data={user_data}
        setUpgradeModalShow = {()=>setShowUpgradeModal(true)}
        hideUpgradeModal = {hideUpgradeModal}
      />
      <SymbolSearchDialog
        show={modalSymbolsShow}
        onHide = {getSymbolNameCallback}
        getDirection={getDirection}
        setExternal_symbols_counter = {setExternal_symbols_counter}
      />
      <UploadBackgroundDialog
        show={modalBackgroundShow}
        onHide={getBackgroundImgNameCallback}
        parentOfImgPickerCallback={backgroundImgCallback}
        pageBackground={pageBackground}
        setPageBackground={setPageBackground}
        getDirection={getDirection}
        book_id={book_id}
        current_page_id={current_page_id}
      />
      <PageSetupDialog
        show={modalPageSetupShow}
        setModalPageSetupShow={setModalPageSetupShow}
        getDirection={getDirection}
        pageOrientation = {pageOrientation}
        setPageOrientation={setPageOrientation}
        boxes={boxes}
        book_id={book_id}
      />
        {/* Conditionally Render Modals */}
        {paidUser ? (
          <SoundPickerDialog
            show={modalSoundPickerShow}
            onHide={() => setModalSoundPickerShow(false)}
            parentOfSoundPickerCallback={soundCallback}
            getDirection={getDirection}
          />
        ) : (
          <UpgradeModalDialog
          show={upgradeModalShow}
          handleDialogHide={hideUpgradeModal}
          getDirection={() => (i18n.language === "he" ? "rtl" : "ltr")}
        />
        )}
      </div>
  );
};