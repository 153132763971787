import React, { useState, useEffect } from "react";
import BooksCarousel from "./BooksCarousel";
import BooksForStudentsStripe from "./BooksForStudentsStripe";
// import { t, use } from 'i18next';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { performBookSearch } from "../Common/SearchAPI";
import { get_all_stories } from "../BookEdit/DBUtils";
import { performAutoMatch } from "./AutoMatchUtils";
import { BsSearch } from "react-icons/bs";
import { MdAutoFixHigh } from "react-icons/md";
import { GiNotebook } from "react-icons/gi";
import "./search.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getStoriesPerStudents,
  useStateWithLocalStorage,
} from "../Common/LocalStorage";
import { UpgradeModalDialog } from "./UpgradeModalDialog";
import {
  LocalStorageKeys,
  storiesJsonDev,
  storiesJsonILDev,
  StoriesJsonProd,
  storiesJsonUSDev,
  UserPermission,
} from "../BookEdit/Consts";
import ConsentModalDialog from "./ConsentModalDialog";
import { OrganizationStoriesPage } from "../Organization/OrganizationStoriesPage";
import { isPaidUser, getUserGroup } from "../UserMgmt/UserAdmin";
import { GetPublicStoriesUtils } from "./GetPublicStoriesUtils";

export const SearchScreen = ({
  userMetaString,
  groupName,
  userData,
  noUserCallback,
}) => {
  const { t, i18n } = useTranslation();
  const [search_result_books, set_search_result_books] = useState([]);
  const [books_for_students, set_books_for_students] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [canCreateStory, setcanCreateStory] = useState(false);
  const [upgradeModalShow, setUpgradeModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setCurrentUserEmail] = useState(null);
  const [showConsentChecked, setShowConsentChecked] = useState(false);
  const getDirection = () =>
    i18n.language === "he" || i18n.language === "ar" ? "rtl" : "ltr";
  const newestTermsVersion = process.env.REACT_APP_TERMS_VER;
  const newestPrivacyVersion = process.env.REACT_APP_PRIVACY_VER;
  const [groupId, setGroupId] = useState(null);
  const [userPermission, setUserPermission] = useState(null);
  const [user, setUser] = useStateWithLocalStorage(
    userData ? userData : LocalStorageKeys.userKey
  );
  const [userMetadata, setUserMetadata] = useStateWithLocalStorage(
    userMetaString ? userMetaString : LocalStorageKeys.USER_GROUP_NAME
  );

  const getUserData = () => {
    let user_data = {};
    try {
      if (typeof userMetaString === "undefined") {
        user_data = JSON.parse(userMetadata);
      } else {
        user_data = JSON.parse(userMetaString);
      }
    } catch (error) {
      console.error("failed to parse usermetadatastring", error);
    }
    return user_data;
  };

  const isObjectEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    async function updateUserMetadata() {
      try {
        let user_data = getUserData();
        let user_permission = null;
        if (user_data) {
          if (
            !isObjectEmpty(user_data) &&
            (!user_data.user_consent ||
              user_data.user_consent.privacy[
                user_data.user_consent.privacy.length - 1
              ].version < newestPrivacyVersion ||
              user_data.user_consent.terms[
                user_data.user_consent.terms.length - 1
              ].version < newestTermsVersion)
          ) {
            setShowConsentChecked(true);
          }

          let userGroup = getUserGroup(user_data);
          if (userGroup && userGroup.group_id) setGroupId(userGroup.group_id);
          if (userGroup && userGroup.permission) {
            user_permission = userGroup.permission;
            setUserPermission(user_permission);
          } else {
            if (user_data.permission) {
              user_permission = user_data.permission;
              setUserPermission(user_data.permission);
            }
          }

          if (user_data.email) setCurrentUserEmail(user_data.email);

          await isPaidUser(user_permission)
            .then((res) => {
              setcanCreateStory(res);
              console.log("isPermittedUser:" + res);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } catch (err) {
        console.error("failed to update user metadata.", err);
      }
      setIsLoading(false);
    }
    updateUserMetadata();
  }, [, canCreateStory]);

  useEffect(() => {
    const fetchPublicStories = async () => {
      try {
        setIsLoading(true);
        const locale = checkLocale();
        const result = await GetPublicStoriesUtils(locale);
        set_search_result_books(result);
      } catch (error) {
        console.error("Error fetching public stories:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPublicStories();
  }, [, i18n.language]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const locale = checkLocale();
      // await get_all_stories('', locale).then(response => {
      //   set_search_result_books(response);
      // }).finally(() => {
      //   setIsLoading(false);
      // // });
      let user_data = getUserData();
      if (user_data && user_data.email) {
        let auto_match_response_localStorage = getStoriesPerStudents();
        if (auto_match_response_localStorage) {
          set_books_for_students(auto_match_response_localStorage);
        }
        let user_groupid = null;
        if (user_data.organizations && user_data.organizations.groups) {
          user_groupid = user_data.organizations.groups[0].group_id;
          console.log("User group id: " + user_groupid);
        }
        performAutoMatch(user_data.email, user_groupid);
      }
    };
    fetchData();
  }, [i18n.language]);

  const showUpgradeModal = () => {
    setUpgradeModalShow(true);
  };

  const hideUpgradeModal = () => {
    setUpgradeModalShow(false);
  };

  const checkLocale = () => {
    console.log("i18n.language:" + i18n.language);
    return i18n.language.includes("he") ? "IL" : "";
  };
  const handleChange = async (e) => {
    console.log("searching for:" + e.target.value);
    e.preventDefault();
    setSearchInput(e.target.value);
    const searchQuery = e.target.value.toLowerCase();
    const locale = checkLocale();
    const localeFilter = locale === "IL" ? "locale:IL" : "locale:-IL";
    setIsLoading(true);

    try {
      const isPaid = await isPaidUser(userPermission);
      if (isPaid) {
        // Paid users perform a search that presumably already filters based on the input.
        const searchResponse = await performBookSearch(searchQuery, [
          "permission:public",
          "deleted:-true",
          localeFilter,
        ]);
        console.log("search response:" + JSON.stringify(searchResponse));
        if (searchResponse && searchResponse.hits) {
          set_search_result_books(
            searchResponse.hits.map((book_result) => book_result)
          );
        }
      } else {
        // For non-paid users, fetch all public stories and then filter them using 'book_name'.
        const allPublicStories = await GetPublicStoriesUtils(locale);
        const filteredStories = allPublicStories.filter((story) =>
          story.book_name.toLowerCase().includes(searchQuery)
        );
        set_search_result_books(filteredStories);
      }
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Container
        dir={getDirection()}
        className={
          i18n.language === "he" || i18n.language === "ar"
            ? "search_container_rtl"
            : ""
        }
      >
        <Row className="search_row_container">
          <Col className="searchContainer">
            <BsSearch className="searchIcon" />
            <input
              className="search_input"
              type="search"
              placeholder={t("search_box_caption")}
              onChange={handleChange}
              value={searchInput}
            />
          </Col>
          <Col xs="auto" className="add_story_col text-end">
            <Link to={groupId ? "/edit/" + groupId : "/edit"}>
              <GiNotebook
                className={
                  "icon add_story_btn" + (getDirection() == "rtl" ? "_rtl" : "")
                }
              />
              <span className="new_story_caption">
                {t("new_story_caption")}
              </span>
            </Link>
          </Col>
          <Col xs="auto" className="add_story_col text-end">
            <Link to={groupId ? "/ai-story/" + groupId : "/ai-story"}>
              <MdAutoFixHigh
                className={
                  "icon add_story_btn" + (getDirection() == "rtl" ? "_rtl" : "")
                }
              />
              <span className="new_story_caption">
                {t("new_ai_story_caption")}
              </span>
            </Link>
          </Col>
        </Row>

        {searchInput && searchInput != "" ? (
          ""
        ) : (
          <BooksForStudentsStripe
            id="books_for_students"
            books={books_for_students}
          />
        )}

        {/*<h4 style={styles.categoryHeader}>{t("search_result_header")}</h4>*/}
        {isLoading ? (
          <div className="categoryHeader">{t("loading_stories")}</div>
        ) : search_result_books && search_result_books.length > 0 ? (
          <div>
            {groupId ? (
              <OrganizationStoriesPage
                userMetaString={userMetaString}
                groupName={groupName}
                isDisabled={"true"}
              />
            ) : (
              ""
            )}
            {console.log("search_result_books:", search_result_books)}
            <h4
              className={
                i18n.language == "he" ? "categoryHeaderHe" : "categoryHeader"
              }
            >
              {searchInput && searchInput != ""
                ? t("search_result_header")
                : t("trending_books_title")}
            </h4>
            <BooksCarousel
              id="search_result_books"
              books={search_result_books}
              user={userEmail}
              getDirection={getDirection}
              groupId={groupId}
            />
          </div>
        ) : (
          <div></div>
        )}

        <UpgradeModalDialog
          show={upgradeModalShow}
          handleDialogHide={hideUpgradeModal}
          getDirection={getDirection}
          noUserCallback={noUserCallback}
        />

        <ConsentModalDialog
          show={showConsentChecked}
          getDirection={getDirection}
          setShowConsentChecked={setShowConsentChecked}
          user={userEmail}
        />
      </Container>
    </div>
  );
};
